.input {
  width: 100%;
  margin: 0 0 16px 0;
  padding: 6px 16px 4px;
  border: 1px solid var(--light-border-color);
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  height: 56px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .label {
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--input-grey-color);
    white-space: nowrap;
    font-family: 'Roboto', sans-serif;

  }

  &.error {
    border-color: var(--error-color) !important;
    animation: var(--shake-error-input-animation);

    .label {
      color: var(--error-color);
    }
  }

  input {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--input-black-color);
    outline: none;
    border: none;
    width: 100%;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    background: #fff;

    &:focus {}

    &::placeholder {
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      color: var(--input-grey-color);
    }

  }

  .clearIcon {
    color: var(--input-grey-color);
    cursor: pointer;

    &:hover {
      color: var(--input-black-color);
    }
  }

  &.hasFocus {
    border-color: var(--input-black-color);

    &.icon {
      .inputIcon {
        color: var(--input-black-color);
      }
    }
  }

  &:hover {
    border-color: var(--input-black-color);
  }

  &.icon {
    display: flex;
    flex-direction: row;
    align-items: center;

    .inputIcon {
      margin-right: 12px;
      color: var(--input-grey-color);
    }

    input::placeholder {
      font-size: 16px;
    }
  }
}

[data-community='phystech4ever'],
[data-community='alumni'] {
  .input {

    &.hasFocus,
    &:hover {
      border: 1px solid var(--primary-color);
    }
  }
}


@media (max-width: 767px) {
  .input {
    &.icon {
      input::placeholder {
        font-size: 14px;
      }
    }
  }
}