.logo {
  width: 250px;
  margin: -7px 0 5px;
}

.miniLogo {
  width: 100%;
  height: 32px;
  object-fit: contain;
  object-position: left;
}

@media (min-width: 576px) {
  .miniLogo {
    height: 100%;
    max-height: 70px;
  }
}

[data-community='dc'] {
  .miniLogo {
    height: 40px;
  }

  @media (min-width: 576px) {
    .miniLogo {
      width: 100%;
      height: 100%;
    }
  }
}

[data-community='ydn'] {
  @media (max-width: 576px) {
    .miniLogo {
      margin-left: 20px;
    }
  }
}