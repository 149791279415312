.loader {
    max-width: 300px;
    min-height: 300px;
    align-self: center;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0 auto;

    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: var(--primary-text-color);
}

