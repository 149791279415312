    .popupContainer {

        .popup {
            position: fixed;
            bottom: 20px;
            right: 24px;
            z-index: var(--top-level-z-index);
            padding: 10px 10px 10px 20px;
            max-width: 320px;
            background-color: var(--success-color);
            border-radius: 5px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            word-wrap: break-word;
            display: flex;
            flex-direction: column;
            gap: 8px;
            color: #fff;

            &.error {
                background-color: var(--error-color);
            }

            &.default {
                background-color: rgba(48, 48, 48, 0.90);
                color: #f2f2f2;
            }

            &.shown {
                animation: fadeIn 0.5s ease-in-out;
            }

            &.hidden {
                animation: fadeOut 0.5s ease-in-out;
                animation-fill-mode: forwards;

            }

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;

                .title {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.4px;

                    a {
                        color: #fff;
                        border-bottom: 1px solid #fff;
                    }
                }

                .closeIcon {
                    transform: scale(0.8);
                    opacity: 0.75;
                    cursor: pointer;
                    min-width: 20px;

                    &:hover {
                        opacity: 1;
                    }
                }
            }



            .content {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }


    @media (max-width: 575px) {
        .popupContainer {
            position: fixed;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            right: auto;
            z-index: 2;

            .popup {
                position: relative;
                right: auto;
                max-width: 100vw;
                width: calc(100vw - 70px);
            }
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
            transform: translateY(0);
        }

        to {
            opacity: 0;
            transform: translateY(20px);
        }
    }