.header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerContainer {
    width: 100%;
    border-bottom: 1px solid #E5E5E5;

    &.shortLanding {
        border: none;
    }
}

.headerContainerLite {
    width: 100%;
    border-bottom: unset;
}

.headerRow {
    flex: 1;
    height: 68px;
    overflow: visible;
}

.logoCol {
    height: 100%;
    display: flex;
    align-items: center;
}

.logo {
    padding: 70px 0 0 30px;
}

.navBarContainer {
    width: 100%;
}

.loginCol {
    flex-wrap: nowrap;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.alreadyMember {
    display: none;
    font-weight: 700;
    margin-right: 20px;
    color: var(--primary-text-color);
}

.langSelectorWrapper {
    color: var(--primary-text-color);

    .langSelector {
        margin-right: 12px;
        position: relative;
        cursor: pointer;

        svg {
            width: 24px;
        }

        &:hover,
        &.active {
            transform: scale(1.1);
        }
    }

    .langOptions {
        position: absolute;
        top: 40px;
        left: -8px;
        display: flex;
        flex: 0 0;
        flex-direction: column;
        align-items: center;
        border-radius: 6px;
        background: #FFF;
        box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 20px;
        z-index: 3;

        .langOption {
            margin: 4px;
            cursor: pointer;


            &.activeLang {
                font-weight: 700;
            }

            &:hover {
                color: var(--primary-color)
            }
        }
    }

    &.hasUser {
        color: var(--dark-secondary-color);

        .langOptions {
            top: 50px;
        }
    }
}

.invite {
    color: var(--primary-color);
    display: flex;
    align-items: center;
    gap: 6px;
}

.inviteModal {

    .inviteModalContent {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;

        h3 {
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 20px;
            color: var(--primary-text-color);
        }

        .inviteLinkContainer {
            position: relative;
            padding: 12px;
            background: var(--secondary-color);
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 6px;
            border-radius: 5px;
            max-width: calc(100vw - 64px);

            .inviteLink {
                color: var(--primary-text-color);
                white-space: pre;
                text-overflow: ellipsis;
                overflow: hidden;
                opacity: 0.85;
                line-height: 24px;
            }

            .copyTip {
                position: absolute;
                right: 70px;
                /* margin-top: 10px; */
                padding: 10px;
                background-color: rgb(51 51 51 / 90%);
                color: #fff;
                border-radius: 5px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
                white-space: nowrap;
                opacity: 0;
                z-index: var(--top-level-z-index);
                transition: opacity 0.2s ease-in-out;
                animation: fadein 1.5s;
            }

            @keyframes fadein {
                0% {
                    opacity: 0;
                }

                10% {
                    opacity: 1;
                }

                90% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                }
            }
        }
    }
}


.fixedNavbar {
    display: none;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background: #FFF;
    box-shadow: 0px -5px 5px 5px rgba(0, 0, 0, 0.35);
    padding-bottom: 2px;
}


@media (min-width: 576px) {
    .headerContainer {
        border-bottom: unset;
        height: 78px;
    }

    .headerRow {
        height: 78px;
    }

    .alreadyMember {
        display: block;
    }

    .inviteModal {
        .inviteModalContent {
            .inviteLinkContainer {
                max-width: unset;

                .inviteLink {
                    max-width: 350px;
                }
            }
        }
    }
}


[data-community='annamoney'] {
    .headerContainer {
        max-width: 100% !important;
        padding: 0 24px !important;
        width: 100%;
    }

    @media (min-width: 576px) {
        .headerContainer {
            padding: 0 40px !important;
        }
    }
}

[data-community='nw'] {
    .headerContainer {
        max-width: 100% !important;
        padding: 0 24px !important;
        width: 100%;
    }

    @media (min-width: 576px) {
        .headerContainer {
            padding: 0 40px !important;
        }
    }
}

[data-community='jewishpoint'] {
    @media (max-width: 576px) {
        .logoCol img {
            max-width: 250px;
            ;
        }
    }
}

[data-community='nw'] {

    .alreadyMember {
        font-weight: 400;
        color: #5B656D;
    }
}

[data-community='websummit'] {
    .headerContainer {
        max-width: 100% !important;
        padding: 0 24px !important;
        width: 100%;
    }

    @media (min-width: 576px) {
        .headerContainer {
            padding: 0 40px !important;
        }
    }

    @media (max-width: 576px) {
        .logoCol img {
            max-width: 180px;
            ;
        }
    }
}

[data-community='columbiaalumni'] {
    .headerContainer {
        max-width: 100% !important;
        padding: 0 24px !important;
        width: 100%;

        button:active {
            background-color: #27579A;
        }
    }

    .logoCol img {
        height: 60px;
    }

    @media (min-width: 576px) {
        .headerContainer {
            padding: 0 40px !important;
        }
    }

    @media(max-width: 640px) {

        .logoCol img {
            margin-top: -15px;
        }
    }
}

[data-community='sfeducation'] {
    .headerContainer {
        max-width: 100% !important;
        padding: 0 24px !important;
        width: 100%;
    }

    @media (min-width: 576px) {
        .headerContainer {
            padding: 0 40px !important;
        }
    }
}

[data-community='dc'] {

    .logoCol {
        height: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 300px !important;
    }

    @media (min-width: 576px) {
        .headerContainer {
            border-bottom: unset;
            height: 70px;
        }

        .headerRow {
            height: 70px;
        }

        .logoCol {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 5px;
            width: 300px !important;
        }

        .alreadyMember {
            display: block;
        }
    }

    @media (max-width: 576px) {
        .logoCol {
            padding-top: 12px;
        }
    }
}

[data-community='powerup'] {
    .headerContainer {
        max-width: 100% !important;
        padding: 0 24px !important;
        width: 100%;
    }

    @media (min-width: 576px) {
        .headerContainer {
            padding: 0 40px !important;
        }
    }
}

[data-community='powerup-venezia'] {
    .headerContainer {
        max-width: 100% !important;
        padding: 0 24px !important;
        width: 100%;
    }

    @media (min-width: 576px) {
        .headerContainer {
            padding: 0 40px !important;
        }
    }
}

[data-community='powerup-napoli'] {
    .headerContainer {
        max-width: 100% !important;
        padding: 0 24px !important;
        width: 100%;
    }

    @media (min-width: 576px) {
        .headerContainer {
            padding: 0 40px !important;
        }
    }
}