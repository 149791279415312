.page {
    height: 100%;
    width: 100%;
    align-self: center;

    &.centered {
        margin: auto;
    }

    a {
        color: var(--primary-color);
    }
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    max-width: 380px;
    min-width: 320px;
    //width: 380px;
    margin: auto;
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;

    .brandLogo {
        min-height: 40px;
    }
}

.loginText {
    padding: 12px 0;

    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height, or 125% */

    text-align: center;

    /* Dark/Elevated */

    color: var(--primary-text-color);
}

.loginSubText {
    padding: 0 0 24px 0;
    color: #4e4e4e;
    font-size: 18px;
    line-height: 28px;

    .subTextAction {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        &:hover {
            color: var(--primary-color);
        }
    }
}

.formControl {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin: 0.5rem 0;
    box-sizing: border-box;
}

.btn {
    display: inline-block;
    width: 100%;
    margin-top: 1.5rem;
    font-weight: normal;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-sizing: border-box;
}

.btn {
    color: #fff;
    background-color: #1a8cb2;
    border-color: #1a8cb2;
}

.btn:hover {
    color: #fff;
    background-color: #176886;
    border-color: #176886;
    text-decoration: none;
}

.errorText {
    display: block;
    width: 100%;
    position: relative;
    padding: .75rem 1.25rem;
    margin: 0.5rem 0;
    border-radius: .25rem;
    color: var(--error-color);
    background-color: var(--error-bg-color);
    border-color: var(--error-border-color);
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;

    b {
        font-weight: bold;
    }
}

.successText {
    color: #24721c;
    background-color: #daf8d7;
    border-color: #cbf5c6;
}

.submitButton {
    margin-bottom: 24px;
}

.bottomQuestion {
    margin-bottom: 24px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--dark-secondary-color);

    &.shortAuth {
        margin-top: 16px;
    }

    &.preSubmit {
        width: 100%;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-align: left;
        margin: -12px 0 16px; // to move towards input, which has margin-bottom: 16px;

        .link {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;
            color: var(--primary-color);
        }
    }

    .link {
        font-weight: bold;
    }
}

.googleButton {
    width: 100%;
    margin-bottom: 24px;

    span {
        width: 100%;
    }
}

.oauth2Button {
    margin-bottom: 24px;

    button {
        font-weight: bold !important
    }

    img {
        max-height: 18px;
        position: relative;
        top: 2px;
    }
}

.orContinueWith {
    margin-bottom: 24px;
    height: 28px;
    width: 100%;

    position: relative;

    &:before {
        position: absolute;
        content: ' ';
        width: 100%;
        height: 1px;
        background-color: var(--light-border-color);
        top: 15px;
        left: 0;
    }

    span {
        padding: 0 16px;
        background-color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);

        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: var(--dark-secondary-color);

        white-space: nowrap;
    }
}

.privacyCheckbox {
    display: flex;
    line-height: 18px;

    .privacyLabel {
        text-align: left;
        font-size: 12px;
    }

    .checkbox {
        margin-bottom: 0;
    }
}

.termsModal {
    h1 {
        line-height: 22px;
    }

    .termsText {
        padding-top: 4px;
        font-size: small;
        overflow-y: scroll;

        h3,
        strong {
            font-weight: bold;
        }

        u,
        a {
            text-decoration: underline;
        }

        h3,
        p,
        li {
            line-height: 22px;
        }

        h3 {
            margin: 24px 0;
            text-align: center;
        }

        p {
            margin-bottom: 12px;
            font-size: 14px;
        }

        ul {
            margin: 16px 0;

            &:first-of-type {
                li {
                    position: relative;
                    padding-left: 16px;

                    &:before {
                        content: '–';
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }

            &:last-of-type {
                list-style: disc inside;
            }

            li {
                margin: 8px 0;
            }
        }

        a {
            color: #0000FF;
        }

    }

    .confirmation {
        margin: 24px 0 0;
        width: 100%;

        .button {
            text-align: center;
        }
    }
}

.otpEmailModal {
    .submitButton {
        margin-bottom: 0;
    }
}


[data-community='annamoney'] {
    .bottomQuestion {
        .link {
            font-weight: normal;
        }
    }
}


@media (min-width: 576px) {
    .page {
        margin: auto;
    }

    .termsModal {

        .termsText {
            padding-top: 12px;
        }

        .confirmation {
            margin-top: 32px;
        }
    }
}

.termsModal .renButton {
    text-align: center;

    button {
        border-radius: 6px;
        font-family: Roboto, sans-serif !important;
        font-weight: 500;
        background-color: #D41367 !important;
        border: none !important;
        color: #FFFFFF;

        &:disabled,
        &:disabled:hover {
            border-radius: 6px !important;
            background-color: #D6D8DA !important;
            color: #FFFFFF;
        }

        &:hover {
            background-color: #E92379 !important;
        }

        &:active {
            background-color: #AB044D !important;
        }
    }
}

[data-community='nw'] {
    .termsModal {
        font-family: Roboto, sans-serif !important;
    }

    .loginText {
        font-family: Geometria, sans-serif !important;
        font-weight: 500;
    }

    .submitButton,
    .termsModal .button {
        button {
            border-radius: 6px;
            font-weight: 500;
            background-color: #D41367 !important;
            border: none !important;
            color: #FFFFFF;

            &:disabled,
            &:disabled:hover {
                border-radius: 6px !important;
                background-color: #D6D8DA !important;
                color: #FFFFFF;
            }

            &:hover {
                background-color: #E92379 !important;
            }

            &:active {
                background-color: #AB044D !important;
            }
        }
    }
}