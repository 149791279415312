@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;1,400&family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: "Patua One";
    src: url("assets/fonts/Patua_One/PatuaOne-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Melancholy Serif Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Melancholy Serif Bold'), url('assets/fonts/MelancholySerif/Melancholy-Serif.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('assets/fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('assets/fonts/ProximaNova/ProximaNova-Medium.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('assets/fonts/ProximaNova/ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Geometria";
    src: url("assets/fonts/geometria/Geometria.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Geometria";
    src: url("assets/fonts/geometria/Geometria-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Geometria";
    src: url("assets/fonts/geometria/Geometria-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "VisbyCF";
    src: url("assets/fonts/VisbyCF/VisbyCF-Regular.otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "VisbyCF";
    src: url("assets/fonts/VisbyCF/VisbyCF-Medium.otf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "VisbyCF";
    src: url("assets/fonts/VisbyCF/VisbyCF-Bold.otf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "VisbyCF";
    src: url("assets/fonts/VisbyCF/VisbyCF-ExtraBold.otf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "PolySans";
    src: url("assets/fonts/PolySans/PolySansQonto-Slim.otf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "PolySans";
    src: url("assets/fonts/PolySans/PolySansQonto-Neutral.otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "PolySans";
    src: url("assets/fonts/PolySans/PolySansQonto-Median.otf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "PolySans";
    src: url("assets/fonts/PolySans/PolySansQonto-Bulky.otf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 1 999;
    src: url('assets/fonts/Rubik/Rubik-Italic-VariableFont_wght.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 1 999;
    src: url('assets/fonts/Rubik/Rubik-VariableFont_wght.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Hauss";
    src: url("assets/fonts/Hauss/als_hauss_regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hauss";
    src: url("assets/fonts/Hauss/als_hauss_medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hauss";
    src: url("assets/fonts/Hauss/als_hauss_bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hauss mono";
    src: url("assets/fonts/Hauss_mono/ALSHaussMono-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hauss mono";
    src: url("assets/fonts/Hauss_mono/ALSHaussMono-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Hauss mono";
    src: url("assets/fonts/Hauss_mono/ALSHaussMono-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}