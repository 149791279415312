.wrapper {
  //max-width: 1180px;
  min-width: 320px;
  //margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-height: 100vh;

  .main {
    // this 2 rules create margin-top: 22px but with 50px overflow-y on top
    padding-top: 72px;
    margin-top: -50px;

    display: flex;
    flex-direction: column;
    background: #ffffff;
    flex: 1;
    align-self: stretch;
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .wrapper {
    min-width: 400px;
  }
}

//header > div {
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  align-items: center;
//  max-width: 100% !important;
//  padding: 0!important;
//  width: 100%;
//  margin: 0 -15px !important;
//}
//
//@media(min-width: 640px) {
//  header > div {
//    max-width: 728px !important;
//    // padding: 0 5% !important;
//  }
//}
//
//@media(min-width: 768px) {
//  header > div {
//    max-width: 992px !important;
//    // padding: 0 3% !important;
//  }
//}
//
//@media(min-width: 1024px) {
//  header > div {
//    max-width: 1140px !important;
//    // padding: 0 35px !important;
//  }
//}


[data-community='annamoney'] {
  * {
    font-family: 'Proxima Nova', sans-serif !important;
  }
}

[data-community='nw'] {
  * {
    font-family: 'Roboto', sans-serif !important;
  }

  h1,
  h2 {
    font-family: 'Geometria', sans-serif !important;
    font-weight: 500 !important;
  }

  h1 {
    font-size: 40px !important;
    line-height: 44px !important;
  }

  h2 {
    font-size: 32px !important;
    line-height: 36px !important;
  }
}

[data-community='websummit'] {
  * {
    font-family: 'Proxima Nova', sans-serif !important;
  }
}

[data-community='jewishpoint'] {
  * {
    font-family: 'VisbyCF', sans-serif !important;
  }
}

[data-community='columbiaalumni'] {
  * {
    font-family: 'Inter', sans-serif !important;
  }
}

[data-community='sfeducation'] {
  * {
    font-family: 'Inter', sans-serif !important;
  }
}

[data-community='ciao'] {
  * {
    font-family: 'VisbyCF', sans-serif !important;
  }
}

[data-community='powerup'] {
  * {
    font-family: 'PolySans', sans-serif !important;
  }
}

[data-community='powerup-venezia'] {
  * {
    font-family: 'PolySans', sans-serif !important;
  }
}

[data-community='powerup-napoli'] {
  * {
    font-family: 'PolySans', sans-serif !important;
  }
}

[data-community='phystech4ever'] {
  * {
    font-family: 'Inter', sans-serif !important;
  }
}

[data-community='alumni'] {
  * {
    font-family: 'Hauss', sans-serif !important;
  }
}

[data-community='connect-entire'] {
  * {
    font-family: 'Nunito sans', sans-serif !important;
  }

  h1 {
    font-family: 'Nunito', sans-serif !important;
    font-weight: 700 !important;
  }

  h1 {
    font-size: 40px !important;
    line-height: 44px !important;
  }

  h2 {
    font-size: 32px !important;
    line-height: 36px !important;
  }
}

[data-community='mentor'] {
  * {
    font-family: 'Hauss', sans-serif !important;
  }
}