.loginBtn {
  &:hover {
    button {
      background-color: var(--primary-background-color);
      border: none;
    }
  }

  &.shortLanding {
    border-radius: 8px;

    button {
      border-radius: 8px;
      background-color: var(--primary-color);
      font-family: Montserrat, sans-serif;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
}

@media (max-width: 575px) {
  .loginBtn {
    button {
      border-radius: 4px;
      padding: 8px 12px;
      font-size: 12px;
      line-height: 16px;
    }

    &.shortLanding {
      button {
        border-radius: 8px !important;
        padding: 8px 20px;
        border-radius: 48px;
        font-size: 14px;
        line-height: 20px;
        min-width: 86px;
      }
    }
  }
}

[data-community='nw'] {

  .loginBtn {
    border-radius: 6px;

    button {
      background-color: #F3F4F4;
      color: #5B656D;
      border: none;
    }

    &:hover {
      color: #323E48;
      box-shadow: 0 5px 16px 0 rgba(87, 108, 135, 0.24);
    }

    &:active {
      color: #5B656D;
      box-shadow: none;
    }
  }
}

[data-community='phystech4ever'] {
  .loginBtn {

    button {
      border: none;
      background-color: var(--primary-color);
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.084px;

      &:hover {
        box-shadow: 0 5px 16px 0 rgba(87, 108, 135, 0.24);
      }
    }
  }
}

[data-community='alumni'] {
  .loginBtn {

    button {
      border: none;
      font-weight: 600;
      color: #fff;

      &:hover {
        box-shadow: 0 5px 16px 0 rgba(87, 108, 135, 0.24);
      }
    }
  }
}