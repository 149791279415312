.landingHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 60px;
    width: calc(100vw - 48px);
    z-index: 2;
    padding: 8px 24px;

    background: #FEFEFE;
    transition: box-shadow 0.15s ease-in-out;

    .wrapper {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        button:hover {
            // darken without changing bg color
            filter: brightness(0.95);
        }

        a:not(.logoLink):hover {
            // darken without changing text color
            filter: brightness(0.65);
        }

        .logo {
            height: 32px;
            width: 127px;
            max-width: 127px;
        }

        .menu {
            border-radius: 8px;

            button {
                padding: 11px;
                border: none;
                border-radius: 8px;
                background: var(--accent-text-color);

                svg {
                    color: var(--primary-color);
                }
            }
        }

        nav {
            position: fixed;
            inset: 0;
            background: rgba(2, 2, 2, 0.80);
            width: 100%;
            animation: darken 0.15s ease-in 0s 1 forwards;
            display: flex;
            flex-direction: column;

            a {
                display: flex;
                padding: 16px 40px;
                color: var(--primary-color);
                font-family: "Proxima Nova", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
            }

            .sections {
                display: flex;
                flex-direction: column;
                margin-top: auto;
                background-color: #FFF;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                animation: slideIn 0.15s ease-in 0s 1 forwards;

                section {
                    display: flex;
                    flex-direction: column;
                    padding: 16px 0;
                    position: relative;
                    z-index: 2;

                    &.authLinks {
                        flex-direction: column-reverse;
                        gap: 8px;
                        padding: 16px 30px;

                        a {
                            padding: 20px 12px;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            box-sizing: border-box;
                            font-weight: 600;

                            &.closeMenu {
                                font-weight: 500;
                            }
                        }

                        .signUp {
                            border-radius: 16px;

                            button {
                                width: 100%;
                                padding: 16px 24px;
                                border: none;
                                border-radius: 16px;
                                background-color: var(--accent-text-color);
                                color: var(--primary-color);
                                font-family: "Proxima Nova", sans-serif;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 150%;
                                letter-spacing: -0.1px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .landingHeader {
        height: 80px;
        box-sizing: border-box;
        width: 100vw;

        .wrapper {
            max-width: 1127px;
            justify-content: unset;
            gap: 60px;

            a {
                display: flex;
                align-items: center;

                .logo {
                    height: 42px;
                    max-height: 74px;
                    width: 150px;
                    max-width: 150px;
                }
            }


            .menu {
                display: none;
            }


            nav {
                position: static;
                background: none;
                inset: unset;
                animation: none;

                .sections {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    animation: none;

                    section {

                        &.navLinks {
                            margin-top: 0;
                            border-radius: 0;
                            position: static;
                            padding: 0;
                            flex-direction: row;

                            ul {
                                display: flex;
                                gap: 32px;

                                li {
                                    a {
                                        padding: 20px 12px;
                                        justify-content: center;
                                    }
                                }
                            }
                        }

                        &.authLinks {
                            margin-top: 0;
                            border-radius: 0;
                            position: static;
                            padding: 0;
                            flex-direction: row;
                            align-items: center;
                            gap: 32px;

                            .closeMenu {
                                display: none;
                            }

                            a {
                                font-weight: 600;
                                min-width: 46px;
                            }

                            .signUp {
                                min-width: 105px;

                                button {
                                    padding: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes darken {
    from {
        background: rgba(2, 2, 2, 0);
    }

    to {
        background: rgba(2, 2, 2, 0.80);
    }
}

@keyframes slideIn {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}