.navButton {
    margin-top: 12px;
    margin-right: -15px;
    padding: 6px 0;

    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.24px;

    display: flex;
    align-items: center;
    gap: 3px;
    color: var(--dark-secondary-color);

    text-decoration: unset;

    svg {
        width: 18px;
        height: 18px;
    }

    &.donate {
        position: relative;
        min-width: 200px;
        box-sizing: border-box;
        text-align: center;
        justify-content: center;
        gap: 10px;
        margin: 10px 0 0;
        padding: 10px 32px;
        border-radius: 4px;
        background-color: var(--primary-color);
        color: #fff;
        cursor: pointer;
        transition: all 0.3s ease-out;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: unset;

        svg {
            width: 24px;
            height: 24px;
        }

        .spots {
            position: absolute;
            top: 0;
            left: 10%;
            z-index: 1;
        }

        &:hover {
            background-color: transparent;
            color: var(--primary-color);

            svg {
                animation: pulse .6s ease-in-out 0s infinite forwards;
            }

            .buttonSpots {
                display: none; // Hide animations on mobile

                @for $i from 1 through 19 {
                    &:nth-of-type(#{$i}) {
                        animation: spot-#{$i} .7s .4 / random(10)+random(10) / 10+s linear infinite;
                    }
                }

                @for $i from 20 through 40 {
                    &:nth-of-type(#{$i}) {
                        animation: spot-#{$i} .7s .4 / random(10)+random(10) / 10+s linear infinite;
                    }
                }

                @for $i from 40 through 46 {
                    &:nth-of-type(#{$i}) {
                        animation: spot-#{$i} .7s .4 / random(10)+random(10) / 10+s linear infinite;
                    }
                }

                @for $i from 46 through 54 {
                    &:nth-of-type(#{$i}) {
                        animation: spot-#{$i} .7s .4 / random(10)+random(10) / 10+s linear infinite;
                    }
                }
            }
        }
    }
}

.donateCol {
    padding: 8px 15px 0;
}

.navButtonProf {
    color: var(--primary-background-color);
    font-weight: 700;
    text-decoration: unset;
}

@media (min-width: 450px) {
    .navButton {
        margin-right: 2px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.24px;
        padding: 8px 0;
        gap: 4px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

@media (min-width: 768px) {

    .donateCol {
        padding: 0 0 0 15px;
    }

    .navButton {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: unset;

        svg {
            width: 24px;
            height: 24px;
        }

        &.donate {
            &:hover {
                .buttonSpots {
                    display: block;
                }
            }
        }
    }


}

.navActiveButton {
    color: var(--primary-color);
    box-shadow: 0 2px 0 var(--primary-color);
}



.notification {
    margin: 24px 15px 0;
    padding: 20px;
    width: 100%;
    border: 1px solid;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.1;
}

.notification.warning {
    background-color: #E5E5E5;
    border-color: #E5E5E5;
}

.notification span {
    color: var(--accent-text-color);
    font-weight: 700;
}

[data-community='nw'] {
    .navButton {
        font-weight: 500;
    }
}


.buttonSpots {
    position: absolute;
    border-radius: 100px;
    background: green;
    opacity: 0;
    animation: opacity 1s;

    @for $i from 1 through 52 {
        &:nth-of-type(#{$i}) {
            transform-origin: 90px - random(10) 20px - random(10);
            background: hsla(350 + random(399), 57% - random(10), 65%, 1);
            box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
            transition: all 1s + random(10)/10;
        }
    }
}

.buttonSpots {
    @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
            padding: random(3) + 2 + px;
            left: -25 + ($i * 12) + px;
            top: 50 + px;
        }
    }

    @for $i from 20 through 40 {
        &:nth-child(#{$i}) {
            padding: random(3) + 2 + px;
            left: -255 + ($i * 12) + px;
            top: -12 + px;
        }
    }

    @for $i from 40 through 46 {
        &:nth-child(#{$i}) {
            padding: random(3) + 2 + px;
            left: 204px;
            top: -488 + ($i * 12) + px;
        }
    }

    @for $i from 46 through 52 {
        &:nth-child(#{$i}) {
            padding: random(3) + 2 + px;
            left: -10px;
            top: -568 + ($i * 12) + px;
        }
    }
}

@for $i from 1 through 20 {
    @keyframes spot-#{$i} {
        from {
            opacity: 0;
        }

        to {
            transform: translateY(30px) translatex(-20px + $i*2);
            opacity: .6;
        }
    }
}

@for $i from 20 through 40 {
    @keyframes spot-#{$i} {
        from {
            opacity: 0;
        }

        to {
            transform: translateY(-30px) translatex(-50px + $i*2);
            opacity: .6;
        }
    }
}

@for $i from 40 through 45 {
    @keyframes spot-#{$i} {
        from {
            opacity: 0;
        }

        to {
            transform: translateY(-86px + $i*2) translatex(40px);
            opacity: .6;
        }
    }
}

@for $i from 46 through 54 {
    @keyframes spot-#{$i} {
        from {
            opacity: 0;
        }

        to {
            transform: translateY(-99px + $i*2) translatex(-40px);
            opacity: .6;
        }
    }
}

@keyframes opacity {
    from {}

    to {
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.04);
        opacity: 0.85;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}