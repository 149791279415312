.wrapper {

  button {
    background: #FFFFFF;
    border: 1px solid var(--light-border-color);
    color: var(--primary-text-color);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px 20px;
    cursor: pointer;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    display: inline-block;
    text-align: center;
    vertical-align: middle;
    transition:
      color .15s ease-in-out,
      background-color .15s ease-in-out,
      border-color .15s ease-in-out,
      box-shadow .15s ease-in-out;

    &:hover {
      color: #FFFFFF;
      background: var(--primary-text-color);
      border-color: var(--primary-text-color);
    }

    &:disabled {
      color: var(--dark-secondary-color) !important;
      background: var(--disabled-color) !important;
      border-color: var(--disabled-color) !important;
      cursor: not-allowed;
    }
  }

  &.wide {
    width: 100%;

    button {
      width: 100%;
    }
  }

  &.small {
    button {
      border-radius: 4px;
      padding: 8px 12px;
      font-size: 12px;
      line-height: 16px;

      .loader {
        height: 12px !important;
        width: 12px !important;
      }
    }
  }

  &.medium {
    button {
      min-width: 130px;
      border-radius: 4px;
      padding: 8px 12px;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &.active {
    button {
      color: #FFFFFF;
      background: var(--primary-text-color);
      border-color: var(--primary-text-color);
    }
  }

  &.danger {
    button {
      background: #FFFFFF;
      border: 1px solid #F44336;
      color: #F44336;

      &:hover {
        color: #FFFFFF;
        background: #F44336;
        border-color: #F44336;
      }
    }

    &.active {
      button {
        color: #FFFFFF;
        background: #F44336;
        border-color: #F44336;
      }
    }
  }

  &.secondary {
    button {
      background: #FFFFFF;
      color: var(--input-grey-color);

      &:hover {
        color: #FFFFFF;
        background: var(--input-grey-color);
        border-color: transparent;
      }
    }

    &.active {
      button {
        color: #FFFFFF;
        background: var(--input-grey-color);
        border-color: transparent;
      }
    }
  }

  &.primary {
    button {
      color: #FFFFFF;
      background-color: var(--primary-color);
      border-color: transparent;
    }
  }

  &.community {
    button {
      color: #FFFFFF;
      background: var(--primary-background-color);
      border-color: var(--primary-background-color);
    }

    &.outline {
      button {
        background: #FFFFFF;
        color: var(--primary-color);
        //-webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
        border-color: var(--secondary-color);

        &:hover {
          color: #FFFFFF;
          //-webkit-background-clip: unset;
          //-webkit-text-fill-color: unset;
          background: var(--primary-color);
          border-color: var(--primary-color);
        }
      }
    }
  }

  &.rounded {
    button {
      border-radius: 100px;
    }
  }

  &.outline {
    button {
      border: none;
      background-color: transparent;
      font-style: normal;
      font-weight: normal;
      line-height: 24px;
      color: #AAAAAA;
      padding: 4px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.icon {
    button {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }

  &.iconOnly {
    button {

      svg {
        margin-right: 0px !important;
      }

      &:hover {
        color: unset;
        background: rgba(17, 17, 17, 0.1);
        border-color: transparent;
      }
    }
  }

  &.loadMoreBtn {
    button {
      border-color: var(--primary-color);
      color: var(--primary-color);
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      width: 165px;

      &:hover,
      &:active {
        background-color: var(--primary-color);
        color: #fff;
      }
    }
  }

  &.loading {
    pointer-events: none;

    button {
      pointer-events: none;

      .loader {
        height: 20px;
        width: 20px;
        border-radius: 100%;
        border: 2px solid currentColor;
        border-top: 2px solid transparent;
        margin: 0 auto;
        animation: spin 1s linear infinite;
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}


@media (min-width: 768px) {
  .wrapper {
    &.loadMoreBtn {
      button {
        padding: 12px 20px;
        font-size: 16px;
        line-height: 24px;
        width: 265px;
      }
    }
  }
}

[data-community='nw'] {

  .wrapper {
    button {
      font-weight: 500;
    }

    &.rounded {
      button {
        border-radius: 6px;
      }
    }

    &.primary,
    &.community {
      button {

        background-color: #D41367;

        &:hover {
          background-color: #E92379;
        }

        &:active {
          background-color: #AB044D;
        }
      }
    }
  }
}