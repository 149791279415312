.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;

    &:not(.isLanding) {
        flex-direction: column;
        gap: 16px !important;
        width: 100%;

        .submitButton {
            max-width: 100%;
        }
    }
}

.submitButton {
    margin: 0;
    width: 200px;
    max-width: 200px;

    button {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.176px;
        color: #FFF;
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
        padding-left: 0;
        padding-right: 0;

        &:disabled {
            color: #FFF !important;
            background-color: var(--primary-color) !important;
            border-color: var(--primary-color) !important;
        }
    }

}

.privacyCheckbox {
    display: flex;
    line-height: 18px;

    .privacyLabel {
        text-align: left;
        font-size: 12px;
    }

    .checkbox {
        margin-bottom: 0;
    }
}

.bottomQuestion {
    margin-bottom: 24px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--dark-secondary-color);

    &.shortAuth {
        margin-top: 16px;
    }

    &.preSubmit {
        width: 100%;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-align: left;
        margin: -12px 0 16px; // to move towards input, which has margin-bottom: 16px;

        .link {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;
            color: var(--primary-color);
        }
    }

    .link {
        font-weight: bold;
    }
}

.input {
    margin: 0;
    padding: 16px;
    background: var(--secondary-background-color, #f5fcff);
    border-color: var(--secondary-background-color, #f5fcff);

    input {
        background: var(--secondary-background-color, #f5fcff);
        background-color: transparent;

        &::placeholder {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.176px;
        }
    }
}

.orContinueWith {
    margin-top: -12px;
    margin-bottom: 12px;
    height: 20px;
    width: 100%;

    position: relative;

    &:before {
        position: absolute;
        content: ' ';
        width: 100%;
        height: 1px;
        background-color: var(--light-border-color);
        top: 11px;
        left: 0;
    }

    span {
        padding: 0 16px;
        background-color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);

        text-align: center;
        color: var(--dark-secondary-color);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.24px;
        white-space: nowrap;
    }
}

.errorText {
    display: block;
    width: 100%;
    position: relative;
    padding: .75rem 1.25rem;
    margin: 0.5rem 0;
    border-radius: .25rem;
    color: var(--error-color);
    background-color: var(--error-bg-color);
    border-color: var(--error-border-color);
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;

    b {
        font-weight: bold;
    }
}

[data-community='phystech4ever'] {
    .submitButton {
        button {
            font-weight: 500;
        }
    }

    .input {
        background: #EBF1F9;
        border-color: #EBF1F9;

        input {
            background: #EBF1F9;

            &::placeholder {
                color: rgba(30, 51, 72, 0.30);
                font-family: 'Inter';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.176px;
            }
        }
    }

    @media (min-width: 576px) {
        .container {
            gap: 20px;
        }
    }
}

[data-community='alumni'] {

    @media (min-width: 576px) {
        .container {
            gap: 20px;
        }

        .submitButton {
            button {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

//fix for extra small screens
@media (max-width: 374px) {
    .container {
        flex-direction: column;
        gap: 14px;

        .submitButton {
            max-width: 100%;
            width: 100%;
        }
    }
}