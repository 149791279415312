.gridContainer {
    width: 100%;
}

.skeleton {
    .row {
        margin-bottom: 48px;

        .col {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &.profileCol {
                width: 100%;

                &.avatarCol {
                    width: auto;
                }
            }

            .avatars,
            .texts,
            .buttons {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            .avatar,
            .text,
            .button,
            .label,
            .panel,
            .tag,
            .profileAvatar,
            .input,
            .pill {
                width: 100%;
                border-radius: 4px;
                background: linear-gradient(90deg, #f0f0f0 25%, #f7f7f7 50%, #f0f0f0 75%);
                background-size: 200% 100%;
                animation: modern-loading 2.5s infinite;
            }

            .avatar {
                border-radius: 30%;
                animation-delay: 0.05s;
                width: 200px;
                height: 200px;
            }

            .buttons {
                flex-direction: row;
                gap: 12px;
            }

            .texts {
                gap: 26px;
            }

            .tags {
                display: flex;
                gap: 8px;
            }

            .label {
                width: 70%;
                height: 30px;
                animation-delay: 0.15s;
            }

            .text {
                height: 40px;
                animation-delay: 0.2s;
            }

            .button {
                height: 40px;
                width: 40px;
            }

            .tag {
                height: 20px;
                animation-delay: 0.05s;
            }

            .pill {
                flex-grow: 1;
                box-sizing: border-box;
                border-radius: 48px;
                height: 50px;
                width: 150px;
                margin: 0 4px 12px;
            }

            .panel {
                width: 100%;
                height: 174px;
                border-radius: 16px;
                margin-bottom: 12px;
            }

            .input {
                height: 56px;
            }

            .profileAvatar {
                width: 200px;
                height: 200px;
                border-radius: 32px;
            }
        }
    }

    .title {
        display: flex;
        gap: 16px;
        margin-bottom: 26px;
        margin-top: 10px;
    }

    .inputs {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;

        .panel {
            height: 128px !important;
            border-radius: 4px !important;
            margin-bottom: 0 !important;
        }
    }

    .profile {
        display: flex;
        width: 100%;
        gap: 30px;
        padding: 0 15px;
    }

    .profileButtons {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 24px;
    }

    .avatarCol {
        .tag {
            width: 140px !important;
            margin: 0 auto;
        }
    }

    .pills {
        display: flex;
        flex-direction: row;
        justify-content: start;
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
    }

    &.surveySkeleton {
        width: 580px;
        margin: 0 auto;
    }
}

.filter {
    width: 100px;
    height: 36px;
    border-radius: 24px;
}

@media (max-width: 991px) {
    .skeleton {
        .row {
            .col {
                .texts {
                    padding-left: 70px;
                }

                &.nameCol {
                    left: 32px !important;
                }
            }
        }

        .title {
            margin-bottom: 24px;
            margin-top: 0;
        }

        .inputs {
            gap: 16px;
        }

        .avatarCol {
            width: 100% !important;
            align-items: center;
            margin-bottom: 30px;
        }
    }
}


@media (max-width: 767px) {
    .skeleton {
        .row {
            margin-top: 12px;

            .col {

                .avatars,
                .texts,
                .buttons {
                    gap: 12px;
                }

                .texts {
                    padding-left: 0px;
                }

                .avatar {
                    width: 140px;
                    height: 140px;
                    margin-top: -10px;
                }

                .panel {
                    height: 120px;
                    margin-bottom: 0;
                }
            }
        }

        .profile {
            flex-direction: column;

            .panel {
                margin-bottom: 0 !important;
            }
        }

        .avatarCol {
            margin-top: -12px;
        }

        &.surveySkeleton {
            width: 510px;
        }

        .cards {
            display: flex;
            gap: 14.15px;
            overflow-y: hidden;
            overflow-x: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;
            width: 120%;

            &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
                /* For Chrome, Safari, and Opera */
            }

            .panel {
                // position: relative;
                // top: -8px;
            }
        }
    }
}

@media (max-width: 575px) {
    .skeleton {
        .row {
            .col {

                .avatars,
                .tests,
                .buttons {
                    gap: 8px;
                }

                .avatar {
                    width: 100px;
                    height: 100px;
                    position: relative;
                    right: 16px;
                }
            }
        }

        &.surveySkeleton {
            width: 100%;
        }
    }
}


@keyframes loading {
    0% {
        opacity: 0.4;
    }

    50% {
        opacity: 0.6;
    }

    100% {
        opacity: 0.4;
    }
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

@keyframes modern-loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}